import React, { useState, useEffect } from "react";
import { Card, CardMedia } from "@mui/material";
import apiService from "../services/apiService";

const KaplanMeier = () => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const fetchUrl = async () => {
      try {
        const response = await apiService.getKaplanMeier();

        if (response) {
          setUrl(response);
        } else {
          console.error("Invalid response format.");
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchUrl();
  }, []);

  return (
    <Card
      elevation={5}
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <CardMedia
        sx={{
          width: { xs: 300, sm: 400, md: 600 }, // sizing for the picture depending on the breakpoints
          height: { xs: 250, sm: 333, md: 500 },
        }}
        image={url}
      />{" "}
    </Card>
  );
};

export default KaplanMeier;
