import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import apiService from "../../services/apiService";

function MapAdmin({ height, width }) {
  const center = [46.79833, 8.23194];

  const [plants, setPlants] = useState([]);

  // fetches the locations
  useEffect(() => {
    const fetchPlants = async () => {
      try {
        const response = await apiService.getLocations();

        if (response && response.hasOwnProperty("locations")) {
          setPlants(response.locations);
        } else {
          console.error("Invalid response format.");
        }
      } catch (error) {
        console.error("Error fetching InverterTypes:", error);
      }
    };

    fetchPlants();
  }, []);

  // laod svg marker from /marker.svg
  const icon = L.icon({
    iconUrl: "/marker.svg",
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });

  return (
    <Card elevation={5}>
      <CardContent display="flex" justifyContent="center" alignItems="center">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">All plants</Typography>
          </Grid>

          <Grid item xs={12}>
            <MapContainer center={center} zoom={7} style={{ height, width }}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              {plants.map((plant) => {
                return (
                  <Marker
                    position={[plant.location_lat, plant.location_lon]}
                    icon={icon}
                  >
                    <Popup>
                      Name: {plant.name}
                      <br />
                      ID: {plant.id}
                    </Popup>
                  </Marker>
                );
              })}
            </MapContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default MapAdmin;
