import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import apiService from "../services/apiService";
import History from "../components/History";
import VerifyStatus from "../components/Update/VerifyStatus";
import OptimizerBroken from "../components/Update/OptimizerBroken";

// define css for the changed rows
const styles = `
  .changed-row {
    border-left: 3px solid #4caf50;
  }
`;

// Add the css to the page
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

function Update() {
  const [plants, setPlants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [otherStatus, setOtherStatus] = useState({});

  const [showOperational, setShowOperational] = useState(true);
  const [showBroken, setShowBroken] = useState(false);
  const [showReplaced, setShowReplaced] = useState(false);
  const [showInactive, setShowInactive] = useState(false);

  const [currentInverter, setCurrentInverter] = useState({});
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [isOptimzierDialogOpen, setIsOptimizerDialogOpen] = useState(false);

  const [isConfirmButtonDialogOpen, setIsConfirmButtonDialogOpen] = useState(0);

  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  // fetches the inverters grouped by plant
  const fetchData = async () => {
    try {
      const result = await apiService.getInvertersGroupedByPlant();
      setPlants(result.plants);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSaveAllOperational = async () => {
    try {
      // Filter plants to get inverters with status "operational"
      const operationalInverters = plants.flatMap((plant) =>
        plant.inverters
          .filter((inverter) => inverter.status === "operational")
          .map((inverter) => ({
            inverterId: inverter.id,
            newStatus: "operational",
            description: otherStatus[inverter.id] || "",
          }))
      );

      // Update the status of operational inverters
      await apiService.updateInverterStatuses({
        changes: operationalInverters,
      });

      setOtherStatus({});
      setIsConfirmButtonDialogOpen(1);
    } catch (error) {
      setIsConfirmButtonDialogOpen(2);
      console.error("Error updating inverter statuses:", error);
    }
  };

  const handleUpdateStatus = async (inverter) => {
    setCurrentInverter({
      ...inverter,
      newStatus: inverter.status,
      inverterId: inverter.id,
      earliest: inverter.earliest,
      oldStatus: inverter.status,
      commissioning_date: inverter.commissioning_date,
      pv_plant_id: inverter.pv_plant_id,
    });

    setIsUpdateDialogOpen(true);
  };

  const handleOptimizerBroken = async (inverter) => {
    setCurrentInverter({
      ...inverter,
      newStatus: "optimizer",
      inverterId: inverter.id,
      earliest: inverter.earliest,
      commissioning_date: inverter.commissioning_date,
    });

    setIsOptimizerDialogOpen(true);
  };

  return (
    <div style={{ padding: "16px" }}>
      <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
        Update
      </Typography>

      {/* Card with information */}
      <Grid container spacing={2} style={{ marginTop: 8 }}>
        <Grid item xs={12}>
          <Card sx={{ padding: 5 }} elevation={3}>
            {/* Content for the second container */}
            <Typography variant="h5" gutterBottom>
              Information about "broken" inverter
            </Typography>
            <Typography variant="body1" gutterBottom>
              If an inverter is broken but can be repaired, the status is first
              set to broken and then back to operational. If the inverter cannot
              be repaired, the status remains broken and a new inverter must be
              entered in the respective plant.
            </Typography>
          </Card>
        </Grid>

        {/* Filter Card */}
        <Grid item xs={12} minWidth={300}>
          <Card sx={{ padding: 5 }} elevation={3}>
            {/* Content for the second container */}
            <Typography variant="h5" gutterBottom>
              Filters
            </Typography>
            <Grid container spacing={0.5}>
              {/* Ccheckboxes */}
              <Grid
                item
                xs={12}
                md={3}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={showOperational}
                  sx={{ color: "green" }}
                  onClick={() => {
                    setShowOperational(!showOperational);
                  }}
                />
                <Typography variant="body1" align="center" gutterBottom>
                  Show operational
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={showBroken}
                  sx={{ color: "green" }}
                  onClick={() => {
                    setShowBroken(!showBroken);
                  }}
                />
                <Typography variant="body1" align="center" gutterBottom>
                  Show broken
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={showReplaced}
                  sx={{ color: "green" }}
                  onClick={() => {
                    setShowReplaced(!showReplaced);
                  }}
                />
                <Typography variant="body1" align="center" gutterBottom>
                  Show replaced
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={showInactive}
                  sx={{ color: "green" }}
                  onClick={() => {
                    setShowInactive(!showInactive);
                  }}
                />
                <Typography variant="body1" align="center" gutterBottom>
                  Show inactive
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* Confirm operational button */}
        <Grid
          item
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
          alignContent={"center"}
          textAlign={"center"}
        >
          <Button
            variant="contained"
            color="info"
            onClick={handleSaveAllOperational}
            style={{
              marginTop: "16px",
              margin: "auto",
            }}
          >
            Confirm status for all operational inverters
          </Button>
        </Grid>
      </Grid>

      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        // Table of inverters grouped by plant
        <Grid container spacing={3}>
          {plants.map((plant) => {
            const shownInverters = plant.inverters.filter(
              (inverter) =>
                ((showOperational && inverter.status === "operational") ||
                  (showBroken && inverter.status === "broken") ||
                  (showReplaced && inverter.status === "replaced")) &&
                (showInactive || plant.active) &&
                (!plant.active || inverter.active)
            );
            return (
              <Grid item key={plant.id} xs={12}>
                <Typography variant="h4" gutterBottom>
                  {plant.name}
                </Typography>
                {shownInverters.length === 0 ? (
                  <Typography variant="body1">
                    No inverters visible due to filters.
                  </Typography>
                ) : (
                  <TableContainer
                    component={Card}
                    style={{ marginBottom: "16px" }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: "12%" }}>Name</TableCell>
                          <TableCell sx={{ width: "12%" }}>Status</TableCell>
                          <TableCell sx={{ width: "14%" }}>
                            Failure/replacement date
                          </TableCell>
                          <TableCell sx={{ width: "13%" }}>
                            Last Verification
                          </TableCell>
                          <TableCell sx={{ width: "16%" }}>
                            Verification
                          </TableCell>
                          <TableCell sx={{ width: "18%" }}>Optimizer</TableCell>
                          <TableCell sx={{ width: "15%" }}>History</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {plant.inverters.map((inverter) =>
                          // check if the plant should be shown or not
                          ((showOperational &&
                            inverter.status === "operational") ||
                            (showReplaced && inverter.status === "replaced") ||
                            (showBroken && inverter.status === "broken")) &&
                          (showInactive || plant.active) &&
                          (!plant.active || inverter.active) ? (
                            <TableRow key={inverter.id}>
                              <TableCell>{inverter.name}</TableCell>
                              <TableCell>{inverter.status}</TableCell>
                              <TableCell>
                                <Typography variant="body1">
                                  {(() => {
                                    try {
                                      const [day, month, year] =
                                        inverter.failure_date.split(".");
                                      const isoDateString = `${year}-${month}-${day}`;
                                      const date = new Date(isoDateString)
                                        .toLocaleDateString("en-GB")
                                        .replace(/\//g, ".");
                                      if (date === "Invalid Date") {
                                        return "";
                                      }
                                      return date;
                                    } catch (error) {
                                      return "";
                                    }
                                  })()}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body1">
                                  {new Date(inverter.last_status_update)
                                    .toLocaleDateString("en-GB")
                                    .replace(/\//g, ".")}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    handleUpdateStatus(inverter);
                                  }}
                                >
                                  <Typography variant="body1">
                                    Verify Status
                                  </Typography>
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    handleOptimizerBroken(inverter);
                                  }}
                                >
                                  <Typography variant="body1">
                                    Optimizer broken
                                  </Typography>
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    setCurrentInverter({ id: inverter.id });
                                    setIsHistoryOpen(true);
                                  }}
                                >
                                  <Typography variant="body1">
                                    History
                                  </Typography>
                                </Button>
                              </TableCell>
                            </TableRow>
                          ) : null
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            );
          })}
        </Grid>
      )}

      {/* History Dialog */}
      <Dialog
        open={isHistoryOpen}
        onClose={() => {
          setIsHistoryOpen(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Inverter change history</DialogTitle>
        <DialogContent>
          <History inverterId={currentInverter.id}></History>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsHistoryOpen(false);
            }}
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Verify Status Dialog */}
      <VerifyStatus
        open={isUpdateDialogOpen}
        onClose={() => {
          setIsUpdateDialogOpen(false);
          fetchData();
        }}
        updatedInverter={currentInverter}
        setUpdatedInverter={setCurrentInverter}
      />

      {/* Dialog for broken optimzer */}
      <OptimizerBroken
        open={isOptimzierDialogOpen}
        onClose={() => {
          setIsOptimizerDialogOpen(false);
          fetchData();
        }}
        updatedInverterOptimizer={currentInverter}
        setUpdatedInverterOptimizer={setCurrentInverter}
      />

      {/* Confirm operational dialog */}
      <Dialog
        open={isConfirmButtonDialogOpen === 1}
        onClose={() => {
          setIsConfirmButtonDialogOpen(0);
        }}
        maxWidth="sm"
        fullWidth
      >
        {isConfirmButtonDialogOpen === 1 ? (
          <DialogTitle>Confirmed all operational inverters</DialogTitle>
        ) : isConfirmButtonDialogOpen === 2 ? (
          <DialogTitle>
            Something went wrong while confirming the operational inverters
          </DialogTitle>
        ) : null}
        <DialogActions>
          <Button
            onClick={() => {
              setIsConfirmButtonDialogOpen(0);
            }}
            color="primary"
            fullWidth
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Update;
