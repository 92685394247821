import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Grid,
  TextField,
  Card,
  CardContent,
  MenuItem,
} from "@mui/material";
import apiService from "../../services/apiService";

const PlantControl = () => {
  const [plants, setPlants] = useState([]);
  const [plantToChange, setPlantToChange] = useState({});

  const fetchPlants = async () => {
    try {
      const response = await apiService.getAllPlants();

      if (response && response.hasOwnProperty("plants")) {
        setPlants(response.plants);
      } else {
        console.error("Invalid response format. 'plants' property not found.");
      }
    } catch (error) {
      console.error("Error fetching plants:", error);
    }
  };

  useEffect(() => {
    fetchPlants();
  }, []);

  const handlePlantManagementInputChange = async (e) => {
    const value = e.target.value;
    setPlantToChange(value);
  };

  const handlePlantManagementSubmit = async () => {
    /* 
    Toggles the visibility of the selected plant
    */
    try {
      console.log(plantToChange);
      const response = await apiService.togglePlantVisibility(plantToChange.id);

      if (response && response.hasOwnProperty("id")) {
        setPlantToChange({});
        console.log("Switched visibility");
        fetchPlants();
      } else {
        console.error("Invalid response when toggling visibility.");
      }
    } catch (error) {
      console.error("Error creating invert type:", error);
    }
  };

  return (
    <Card elevation={5}>
      <CardContent>
        <Typography variant="h5">
          Remove plants from global statistis
        </Typography>
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            "align-items": "center",
          }}
        >
          <Grid item xs={12} sm={8}>
            <TextField
              select
              label="Users"
              value={plantToChange}
              onChange={handlePlantManagementInputChange}
              fullWidth
              margin="normal"
              displayEmpty // Add this prop to display the empty value
            >
              {plants.map((u) => (
                <MenuItem value={u}>
                  {u.name} - {u.ac_power} - {u.dc_power} -{" "}
                  {u.incorporate_to_statistics ? "Visible" : "Invisible"}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              justifyContent: "center",
              "align-items": "center",
            }}
          >
            <Button
              variant="contained"
              color="info"
              onClick={handlePlantManagementSubmit}
            >
              Toggle statistic visibility
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PlantControl;
