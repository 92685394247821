// apiService.js
// The apiService object is used to comunicate with the backend of the website

// Use process.env to access environment variables
const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api/v1";

// get auth token from local storage
const getAuthToken = () => {
  const token = localStorage.getItem("_auth");
  return token;
};

const apiService = {
  getName: async (id, entity_type) => {
    try {
      var token = getAuthToken();
      const response = await fetch(
        `${API_BASE_URL}/names?id=${id}&entity_type=${entity_type}`,
        {
          headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Name Data:", data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  getPlants: async () => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/plants`, {
        headers: {
          ContentType: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("Data:", data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  getPlant: async (id) => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/plants/${id}`, {
        headers: {
          ContentType: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("Plant Data:", data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  updatePlant: async (id, properties) => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/plants/${id}`, {
        method: "PUT",
        body: JSON.stringify(properties),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("Update plant response:", data);
      return data;
    } catch (error) {
      console.error("Update plant error:", error);
      throw error;
    }
  },

  deletePlant: async (id) => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/plants/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("Delete plant response:", data);
      return { response, data };
    } catch (error) {
      console.error("Delete plant error:", error);
      throw error;
    }
  },

  getInverters: async (plant_id) => {
    try {
      var token = getAuthToken();
      const response = await fetch(
        `${API_BASE_URL}/plants/${plant_id}/inverters`,
        {
          headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Data:", data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  createInverter: async (plant_id, inverter) => {
    try {
      var token = getAuthToken();
      const response = await fetch(
        `${API_BASE_URL}/plants/${plant_id}/inverters`,
        {
          method: "POST",
          body: JSON.stringify(inverter),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Create inverter response:", data);
      return data;
    } catch (error) {
      console.error("Create inverter error:", error);
      throw error;
    }
  },

  getInverter: async (plant_id, inverter_id) => {
    try {
      var token = getAuthToken();
      const response = await fetch(
        `${API_BASE_URL}/plants/${plant_id}/inverters/${inverter_id}`,
        {
          headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Data:", data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  updateInverter: async (plant_id, inverter_id, properties) => {
    try {
      var token = getAuthToken();
      const response = await fetch(
        `${API_BASE_URL}/plants/${plant_id}/inverters/${inverter_id}`,
        {
          method: "PUT",
          body: JSON.stringify(properties),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Update inverter response:", data);
      return data;
    } catch (error) {
      console.error("Update inverter error:", error);
      throw error;
    }
  },

  deleteInverter: async (plant_id, inverter_id) => {
    try {
      var token = getAuthToken();
      const response = await fetch(
        `${API_BASE_URL}/plants/${plant_id}/inverters/${inverter_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Delete inverter response:", data);
      return data;
    } catch (error) {
      console.error("Delete inverter error:", error);
      throw error;
    }
  },

  createString: async (plant_id, inverter_id, string) => {
    try {
      var token = getAuthToken();
      const response = await fetch(
        `${API_BASE_URL}/plants/${plant_id}/inverters/${inverter_id}/strings`,
        {
          method: "POST",
          body: JSON.stringify(string),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Create string response:", data);
      return data;
    } catch (error) {
      console.error("Create string error:", error);
      throw error;
    }
  },

  getStrings: async (plant_id, inverter_id) => {
    try {
      var token = getAuthToken();
      const response = await fetch(
        `${API_BASE_URL}/plants/${plant_id}/inverters/${inverter_id}/strings`,
        {
          headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Data:", data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  getString: async (plant_id, inverter_id, string_id) => {
    try {
      var token = getAuthToken();
      const response = await fetch(
        `${API_BASE_URL}/plants/${plant_id}/inverters/${inverter_id}/strings/${string_id}`,
        {
          headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Data:", data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  updateString: async (plant_id, inverter_id, string_id, properties) => {
    try {
      var token = getAuthToken();
      const response = await fetch(
        `${API_BASE_URL}/plants/${plant_id}/inverters/${inverter_id}/strings/${string_id}`,
        {
          method: "PUT",
          body: JSON.stringify(properties),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Update string response:", data);
      return data;
    } catch (error) {
      console.error("Update string error:", error);
      throw error;
    }
  },

  deleteString: async (plant_id, inverter_id, string_id) => {
    try {
      var token = getAuthToken();
      const response = await fetch(
        `${API_BASE_URL}/plants/${plant_id}/inverters/${inverter_id}/strings/${string_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Delete string response:", data);
      return data;
    } catch (error) {
      console.error("Delete string error:", error);
      throw error;
    }
  },

  getInvertersGroupedByPlant: async () => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/plants/inverter-grouped`, {
        headers: {
          ContentType: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("Data:", data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  updateInverterStatus: async (inverter_id, status) => {
    try {
      var token = getAuthToken();
      const response = await fetch(
        `${API_BASE_URL}/inverters/${inverter_id}/status`,
        {
          method: "PUT",
          body: JSON.stringify({ status }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Update inverter status response:", data);
      return data;
    } catch (error) {
      console.error("Update inverter status error:", error);
      throw error;
    }
  },

  updateInverterStatuses: async (inverterStatuses) => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/status`, {
        method: "POST",
        body: JSON.stringify(inverterStatuses),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("Update inverter statuses response:", data);
      return data;
    } catch (error) {
      console.error("Update inverter statuses error:", error);
      throw error;
    }
  },

  getUserStats: async () => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/userstats`, {
        headers: {
          ContentType: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("Data:", data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  getGlobalStats: async () => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/globalstats`, {
        headers: {
          ContentType: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("Data:", data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  login: async (code, redirectURI) => {
    try {
      const response = await fetch(`${API_BASE_URL}/auth`, {
        method: "POST",
        body: JSON.stringify({ code, redirectURI }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log("Login response:", data);
      return data;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  },

  // get request to /auth endpoint to get user info
  getAuth: async () => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/auth`, {
        headers: {
          ContentType: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("Auth response:", data);
      return data;
    } catch (error) {
      console.error("Auth error:", error);
      throw error;
    }
  },

  createPlant: async (plant) => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/plants`, {
        method: "POST",
        body: JSON.stringify(plant),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("Create plant response:", data);
      return data;
    } catch (error) {
      console.error("Create plant error:", error);
      throw error;
    }
  },

  getInverterTypes: async () => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/inverter-types`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  createInverterType: async (new_inv_type) => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/inverter-types`, {
        method: "POST",
        body: JSON.stringify(new_inv_type),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error creating inverter type:", error);
      throw error;
    }
  },

  getModuleTypes: async () => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/module-types`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  createModuleType: async (new_module_tpye) => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/module-types`, {
        method: "POST",
        body: JSON.stringify(new_module_tpye),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error creating module type:", error);
      throw error;
    }
  },

  getUsers: async () => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  makeAdmin: async (user) => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/users`, {
        method: "PUT",
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error making admin:", error);
      throw error;
    }
  },

  getInverterStatus: async () => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/status`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  getKaplanMeier: async () => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/kaplan-meier`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.blob();
      return URL.createObjectURL(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  getLocations: async () => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/plants/locations`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  getAllPlants: async () => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/plant-visibility`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  },

  togglePlantVisibility: async (id) => {
    try {
      var token = getAuthToken();
      const response = await fetch(`${API_BASE_URL}/plant-visibility`, {
        method: "PUT",
        body: JSON.stringify({ id: id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error making admin:", error);
      throw error;
    }
  },

  API_BASE: API_BASE_URL,
};

export default apiService;
