import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  TextField,
  Typography,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Collapse,
  MenuItem,
} from "@mui/material";
import apiService from "../services/apiService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { useNavigate } from "react-router-dom";

const CreateStringDialog = ({ isOpen, onClose, plantId, inverterId }) => {
  const navigate = useNavigate();

  const [moduleDropdown, setModuleDropdown] = useState({});

  // Fetch module types for the dropdown
  useEffect(() => {
    const fetchModuleTypes = async () => {
      try {
        const response = await apiService.getModuleTypes();

        if (response && response.hasOwnProperty("types")) {
          setModuleDropdown(response.types);
        } else {
          console.error("Invalid response format.");
        }
      } catch (error) {
        console.error("Error fetching module types:", error);
      }
    };

    fetchModuleTypes();
  }, []);

  const [newString, setNewString] = useState({
    module_manufacturer: "",
    module_manufacturer_custom: "",
    module_type: "",
    module_type_custom: "",
  });
  const [stringCreateDialogErrors, setStringCreateDialogErrors] = useState({});

  const [optimizerInfoOpen, setOptimizerInfoOpen] = useState(false);
  const [optionalOpen, setOptionalOpen] = useState(false);

  const changeDialogErrors = (name, value) => {
    setStringCreateDialogErrors((errors) => ({
      ...errors,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    /*
    This function gets called each time the input of a
    Textfield gets changed. It changes the stored value for 
    a field to the input of the textfield
    */
    const { name, value } = e.target;

    if (name === "module_manufacturer_custom" && !value) {
      setNewString((prev) => ({
        ...prev,
        module_manufacturer: "",
        module_manufacturer_custom: "",
        module_type: "",
        module_type_custom: "",
      }));
    }

    if (
      (name === "module_type_custom" && !value) ||
      name === "module_manufacturer"
    ) {
      setNewString((prev) => ({
        ...prev,
        module_type: "",
        module_type_custom: "",
      }));
    }

    setNewString((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddString = async () => {
    /*
    Creates a new plant with the data from the form
    */
    const formIsReady = checkStringCreateDialogErrors();
    if (!formIsReady) {
      console.log("There are errors in the form");
      console.log(stringCreateDialogErrors);
    }

    try {
      const response = await apiService.createString(
        plantId,
        inverterId,
        newString
      );

      if (response && response.hasOwnProperty("id")) {
        navigate(
          `/plants/${plantId}/inverter/${inverterId}/strings/${response.id}`
        );
      }
      onClose();
    } catch (error) {
      console.error("Error adding new string:", error);
    }
  };

  const checkStringCreateDialogErrors = () => {
    /*
    Checks if the input of the form values are valid.
    Sets the error below the fields if it is not the case.
    */

    if (newString.module_manufacturer_custom) {
      newString.module_manufacturer = newString.module_manufacturer_custom;
    }

    if (newString.module_type_custom) {
      newString.module_type = newString.module_type_custom;
    }

    const errors = {};
    if (!newString.name) {
      errors.name = "String name is required";
    }
    if (newString.numoptimizer < 0) {
      errors.numoptimizer = "Number of optimizers cannot be negative";
    }
    if (newString.dc_power_optimizer < 0) {
      errors.dc_power_optimizer = "Optimizer DC power cannot be negative";
    }
    if (!newString.nummodules) {
      errors.nummodules = "Number of modules is required";
    }
    if (newString.nummodules <= 0) {
      errors.nummodules = "Number of modules cannot be negative";
    }
    if (!newString.dc_power_per_module) {
      errors.dc_power_per_module = "Module DC power is required";
    }
    if (newString.dc_power_per_module <= 0) {
      errors.dc_power_per_module = "Module DC power cannot be negativ";
    }
    if (!newString.module_type) {
      errors.module_type = "Module type is required";
    }
    if (!newString.module_manufacturer) {
      errors.module_manufacturer = "Module manufacturer is required";
    }
    if (newString.orientation < 0) {
      errors.orientation = "Orientation cannot be negative";
    }
    if (newString.tilt < 0 || newString.titl > 90) {
      errors.orientation = "Orientation must be between 0 and 90 degrees";
    }
    if (newString.module_cable_length < 0) {
      errors.orientation = "Module cable length cannot be negative";
    }
    if (newString.module_cable_cross_section < 0) {
      errors.orientation = "Module cable cross section cannot be negative";
    }
    if (newString.optimizer_cable_length < 0) {
      errors.orientation = "Optimizer cable length cannot be negative";
    }
    if (newString.optimizer_cable_cross_section < 0) {
      errors.orientation = "Optimizer cable cross section cannot be negative";
    }
    if (newString.voltage_mpp < 0) {
      errors.orientation = "MPP voltage cannot be negative";
    }
    if (newString.voltage_oc < 0) {
      errors.orientation = "Open Circuit Voltage cannot be negative";
    }
    setStringCreateDialogErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add String</DialogTitle>
      <DialogContent>
        {/* String name */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="String Name"
              name="name"
              value={newString.name}
              placeholder="e.g. String 1"
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              error={Boolean(stringCreateDialogErrors.name)}
              helperText={stringCreateDialogErrors.name}
              onKeyDown={(e) => (e.key === "Enter" ? handleAddString() : null)}
              onBlur={() => {
                if (!newString.name) {
                  changeDialogErrors("name", "String name is required");
                } else {
                  changeDialogErrors("name", "");
                }
              }}
            />
          </Grid>

          {/* Number of modules */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Number of Modules"
              name="nummodules"
              type="number"
              value={newString.nummodules}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              error={Boolean(stringCreateDialogErrors.nummodules)}
              helperText={stringCreateDialogErrors.nummodules}
              onKeyDown={(e) => (e.key === "Enter" ? handleAddString() : null)}
              sx={{
                "input[type=number]": {
                  "-moz-appearance": "textfield",
                },
                "input[type=number]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "input[type=number]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
              }}
              onBlur={() => {
                if (!newString.nummodules) {
                  changeDialogErrors(
                    "nummodules",
                    "Number of modules are required"
                  );
                } else if (newString.nummodules < 0) {
                  changeDialogErrors(
                    "nummodules",
                    "Number of modules cannot be negative"
                  );
                } else {
                  changeDialogErrors("nummodules", "");
                }
              }}
            />
          </Grid>

          {/* Power per module */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Power per Module in W"
              name="dc_power_per_module"
              type="number"
              value={newString.dc_power_per_module}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              error={Boolean(stringCreateDialogErrors.dc_power_per_module)}
              helperText={stringCreateDialogErrors.dc_power_per_module}
              InputProps={{
                endAdornment: <InputAdornment position="end">W</InputAdornment>,
              }}
              onKeyDown={(e) => (e.key === "Enter" ? handleAddString() : null)}
              sx={{
                "input[type=number]": {
                  "-moz-appearance": "textfield",
                },
                "input[type=number]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "input[type=number]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
              }}
              onBlur={() => {
                if (!newString.dc_power_per_module) {
                  changeDialogErrors(
                    "dc_power_per_module",
                    "DC power per module is required"
                  );
                } else if (newString.dc_power_per_module < 0) {
                  changeDialogErrors(
                    "dc_power_per_module",
                    "DC power per module cannot be negative"
                  );
                } else {
                  changeDialogErrors("dc_power_per_module", "");
                }
              }}
            />
          </Grid>

          {/* Module Manufacturer*/}
          <Grid item xs={12}>
            {newString.module_manufacturer ===
              "-- Manufacturer not on the list --" ||
            newString.module_manufacturer_custom ? (
              <TextField
                label="Module Manufacturer"
                name="module_manufacturer_custom"
                value={newString.module_manufacturer_custom}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={Boolean(
                  stringCreateDialogErrors.module_manufacturer_custom
                )}
                helperText={stringCreateDialogErrors.module_manufacturer_custom}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleAddString() : null
                }
                onBlur={() => {
                  if (!newString.module_manufacturer_custom) {
                    newString.module_manufacturer = "";
                  } else {
                    changeDialogErrors("module_manufacturer", "");
                  }
                }}
              />
            ) : (
              <TextField
                select
                label="Module Manufacturer"
                name="module_manufacturer"
                value={newString.module_manufacturer}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={Boolean(stringCreateDialogErrors.module_manufacturer)}
                helperText={stringCreateDialogErrors.module_manufacturer}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleAddString() : null
                }
                onBlur={() => {
                  if (!newString.module_manufacturer) {
                    changeDialogErrors(
                      "module_manufacturer",
                      "Module manufacturer is required"
                    );
                  } else {
                    changeDialogErrors("module_manufacturer", "");
                  }
                }}
              >
                {Object.entries(moduleDropdown).map(([key, value]) => (
                  <MenuItem value={key}>{key}</MenuItem>
                ))}
              </TextField>
            )}
          </Grid>

          {/* Module Type */}
          <Grid item xs={12}>
            {newString.module_manufacturer ===
              "-- Manufacturer not on the list --" ||
            newString.module_manufacturer_custom ||
            newString.module_type === "-- Module type not on the list --" ||
            newString.module_type_custom ? (
              <TextField
                label="Module Type"
                name="module_type_custom"
                value={newString.module_type_custom}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={Boolean(stringCreateDialogErrors.module_type_custom)}
                helperText={stringCreateDialogErrors.module_type_custom}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleAddString() : null
                }
                onBlur={() => {
                  if (!newString.module_type_custom) {
                    newString.module_type = "";
                  } else {
                    changeDialogErrors("module_type", "");
                  }
                }}
              />
            ) : (
              <TextField
                select
                label="Module Type"
                name="module_type"
                value={newString.module_type}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={Boolean(stringCreateDialogErrors.module_type)}
                helperText={stringCreateDialogErrors.module_type}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleAddString() : null
                }
                onBlur={() => {
                  if (!newString.module_type) {
                    changeDialogErrors(
                      "module_type",
                      "Module type is required"
                    );
                  } else {
                    changeDialogErrors("module_type", "");
                  }
                }}
              >
                {Object.keys(moduleDropdown).includes(
                  newString.module_manufacturer
                )
                  ? moduleDropdown[newString.module_manufacturer].map(
                      (type) => <MenuItem value={type}>{type}</MenuItem>
                    )
                  : null}
              </TextField>
            )}
          </Grid>

          {/* optimizer data */}
          <Grid item xs={12}>
            <Button
              onClick={() => setOptimizerInfoOpen(!optimizerInfoOpen)}
              variant="text"
              sx={{
                backgroundColor: "transparent",
                typography: "body1",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
              disableElevation
              disableRipple
              onHover={{}}
              color="inherit"
            >
              {optimizerInfoOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              <Typography variant="h6" style={{ textTransform: "none" }}>
                Optimizer
              </Typography>
            </Button>
          </Grid>

          {/* Number of Optimizer */}
          <Grid item xs={12}>
            <Collapse in={optimizerInfoOpen} timeout="auto" unmountOnExit>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Number of Optimizers"
                    name="numoptimizer"
                    type="number"
                    value={newString.numoptimizer}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringCreateDialogErrors.numoptimizer)}
                    helperText={stringCreateDialogErrors.numoptimizer}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleAddString() : null
                    }
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        newString.numoptimizer &&
                        newString.numoptimizer < 0
                      ) {
                        changeDialogErrors(
                          "numoptimizer",
                          "Number of optimizer cannot be negative"
                        );
                      } else {
                        changeDialogErrors("numoptimizer", "");
                      }
                    }}
                  />
                </Grid>

                {/* Power per Optimizer */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Power per Optimizer in W"
                    name="dc_power_optimizer"
                    type="number"
                    value={newString.dc_power_optimizer}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringCreateDialogErrors.dc_power_optimizer)}
                    helperText={stringCreateDialogErrors.dc_power_optimizer}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">W</InputAdornment>
                      ),
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleAddString() : null
                    }
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        newString.dc_power_optimizer &&
                        newString.dc_power_optimizer < 0
                      ) {
                        changeDialogErrors(
                          "dc_power_optimizer",
                          "DC power of the optimizer cannot be negative"
                        );
                      } else {
                        changeDialogErrors("dc_power_optimizer", "");
                      }
                    }}
                  />
                </Grid>

                {/* Manufacturer of the Optimizer */}
                <Grid item xs={12}>
                  <TextField
                    label="Optimizer Manufacturer"
                    name="optimizer_manufacturer"
                    value={newString.optimizer_manufacturer}
                    placeholder="e.g. Huawei"
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(
                      stringCreateDialogErrors.optimizer_manufacturer
                    )}
                    helperText={stringCreateDialogErrors.optimizer_manufacturer}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleAddString() : null
                    }
                  />
                </Grid>

                {/* Type of the Optimizer */}
                <Grid item xs={12}>
                  <TextField
                    label="Optimizer Type"
                    name="optimizer_type"
                    value={newString.optimizer_type}
                    placeholder="e.g. SUN2000-450W-P2"
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringCreateDialogErrors.optimizer_type)}
                    helperText={stringCreateDialogErrors.optimizer_type}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleAddString() : null
                    }
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            <Button
              onClick={() => setOptionalOpen(!optionalOpen)}
              variant="text"
              sx={{
                backgroundColor: "transparent",
                typography: "body1",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
              disableElevation
              disableRipple
              onHover={{}}
              color="inherit"
            >
              {optionalOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              <Typography variant="h6" style={{ textTransform: "none" }}>
                Optional Information
              </Typography>
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Collapse in={optionalOpen} timeout="auto" unmountOnExit>
              <Grid container spacing={2}>
                {/* Orientation */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Orientation in degrees"
                    name="orientation"
                    type="number"
                    value={newString.orientation}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringCreateDialogErrors.orientation)}
                    helperText={stringCreateDialogErrors.orientation}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleAddString() : null
                    }
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (newString.orientation && newString.orientation < 0) {
                        changeDialogErrors(
                          "orientation",
                          "Orientation cannot be negative"
                        );
                      } else {
                        changeDialogErrors("orientation", "");
                      }
                    }}
                  />
                </Grid>

                {/* Tilt */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Tilt in degrees"
                    name="tilt"
                    type="number"
                    value={newString.tilt}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringCreateDialogErrors.tilt)}
                    helperText={stringCreateDialogErrors.tilt}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleAddString() : null
                    }
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (newString.tilt && newString.tilt < 0) {
                        changeDialogErrors("tilt", "Tilt cannot be negative");
                      } else if (newString.tilt && newString.tilt > 90) {
                        changeDialogErrors(
                          "tilt",
                          "Tilt cannot be greater than 90"
                        );
                      } else {
                        changeDialogErrors("tilt", "");
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1">
                    Orientation is south-based, so 0 degrees is South, 90
                    degrees is West, 180 North, 270 East... The tilt goes from 0
                    to 90, where 0 means the module is parallel to the ground
                    and 90 means it "stands upright".
                  </Typography>
                </Grid>

                {/* Mounting Type */}
                <Grid item xs={12}>
                  <TextField
                    label="Mounting Type"
                    name="mounting_type"
                    value={newString.mounting_type}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringCreateDialogErrors.mounting_type)}
                    helperText={stringCreateDialogErrors.mounting_type}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleAddString() : null
                    }
                  />
                </Grid>

                {/* Module cayble length */}
                <Grid item xs={12}>
                  <TextField
                    label="Module cable length in m"
                    name="module_cable_length"
                    type="number"
                    value={newString.module_cable_length}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(
                      stringCreateDialogErrors.module_cable_length
                    )}
                    helperText={stringCreateDialogErrors.module_cable_length}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleAddString() : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                    }}
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        newString.module_cable_length &&
                        newString.module_cable_length < 0
                      ) {
                        changeDialogErrors(
                          "module_cable_length",
                          "Module cable length cannot be negative"
                        );
                      } else {
                        changeDialogErrors("module_cable_length", "");
                      }
                    }}
                  />
                </Grid>

                {/* Module cable cross section */}
                <Grid item xs={12}>
                  <TextField
                    label="Module cable cross section in mm²"
                    name="module_cable_cross_section"
                    type="number"
                    value={newString.module_cable_cross_section}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(
                      stringCreateDialogErrors.module_cable_cross_section
                    )}
                    helperText={
                      stringCreateDialogErrors.module_cable_cross_section
                    }
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleAddString() : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">mm²</InputAdornment>
                      ),
                    }}
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        newString.module_cable_cross_section &&
                        newString.module_cable_cross_section < 0
                      ) {
                        changeDialogErrors(
                          "module_cable_cross_section",
                          "Module cable cross section cannot be negative"
                        );
                      } else {
                        changeDialogErrors("module_cable_cross_section", "");
                      }
                    }}
                  />
                </Grid>

                {/* MPP Voltage */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="MPP Voltage in V"
                    name="voltage_mpp"
                    type="number"
                    value={newString.voltage_mpp}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringCreateDialogErrors.voltage_mpp)}
                    helperText={stringCreateDialogErrors.voltage_mpp}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleAddString() : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">V</InputAdornment>
                      ),
                    }}
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (newString.voltage_mpp && newString.voltage_mpp < 0) {
                        changeDialogErrors(
                          "voltage_mpp",
                          "MPP voltage cannot be negative"
                        );
                      } else {
                        changeDialogErrors("voltage_mpp", "");
                      }
                    }}
                  />
                </Grid>

                {/* OC Voltage */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Open Circuit Voltage in V"
                    name="voltage_oc"
                    type="number"
                    value={newString.voltage_oc}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringCreateDialogErrors.voltage_oc)}
                    helperText={stringCreateDialogErrors.voltage_oc}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleAddString() : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">V</InputAdornment>
                      ),
                    }}
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (newString.voltage_oc && newString.voltage_oc < 0) {
                        changeDialogErrors(
                          "voltage_oc",
                          "Open Circuit voltage cannot be negative"
                        );
                      } else {
                        changeDialogErrors("voltage_oc", "");
                      }
                    }}
                  />
                </Grid>

                {/* Optimizer mounting place */}
                <Grid item xs={12}>
                  <TextField
                    label="Optimizer Mounting Place"
                    name="optimizer_mounting_place"
                    value={newString.optimizer_mounting_place}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(
                      stringCreateDialogErrors.optimizer_mounting_place
                    )}
                    helperText={
                      stringCreateDialogErrors.optimizer_mounting_place
                    }
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleAddString() : null
                    }
                  />
                </Grid>

                {/* optimizer cayble length */}
                <Grid item xs={12}>
                  <TextField
                    label="Optimizer cable length in m"
                    name="optimizer_cable_length"
                    type="number"
                    value={newString.optimizer_cable_length}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(
                      stringCreateDialogErrors.optimizer_cable_length
                    )}
                    helperText={stringCreateDialogErrors.optimizer_cable_length}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleAddString() : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                    }}
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        newString.optimizer_cable_length &&
                        newString.optimizer_cable_length < 0
                      ) {
                        changeDialogErrors(
                          "optimizer_cable_length",
                          "optimizer cable length cannot be negative"
                        );
                      } else {
                        changeDialogErrors("optimizer_cable_length", "");
                      }
                    }}
                  />
                </Grid>

                {/* optimizer cable cross section */}
                <Grid item xs={12}>
                  <TextField
                    label="Optimizer cable cross section in mm²"
                    name="optimizer_cable_cross_section"
                    type="number"
                    value={newString.optimizer_cable_cross_section}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(
                      stringCreateDialogErrors.optimizer_cable_cross_section
                    )}
                    helperText={
                      stringCreateDialogErrors.optimizer_cable_cross_section
                    }
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleAddString() : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">mm²</InputAdornment>
                      ),
                    }}
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        newString.optimizer_cable_cross_section &&
                        newString.optimizer_cable_cross_section < 0
                      ) {
                        changeDialogErrors(
                          "optimizer_cable_cross_section",
                          "Optimizer cable cross section cannot be negative"
                        );
                      } else {
                        changeDialogErrors("optimizer_cable_cross_section", "");
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleAddString} color="primary">
          Add String
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateStringDialog;
