import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Grid,
  TextField,
  Card,
  CardContent,
  MenuItem,
} from "@mui/material";
import apiService from "../../services/apiService";

const RoleManagement = () => {
  // User Role Change
  const [users, setUsers] = useState([]);
  const [userRoleChange, setUserRoleChange] = useState({
    id: "",
    email: "",
    user_role: "",
  });

  const fetchUsers = async () => {
    try {
      const response = await apiService.getUsers();

      if (response && response.hasOwnProperty("users")) {
        setUsers(response.users);
      } else {
        console.error("Invalid response format.");
      }
    } catch (error) {
      console.error("Error fetching InverterTypes:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleUserRoleChangeInputChange = async (e) => {
    const value = e.target.value;
    setUserRoleChange(value);
  };

  const handleUserRoleChangeSubmit = async () => {
    /*
    Swithes the role of the selected user
    normalo -> admin and vice versa
    */
    try {
      const response = await apiService.makeAdmin(userRoleChange);

      if (response && response.hasOwnProperty("id")) {
        setUserRoleChange({});
        console.log("Created a new admin");
        fetchUsers();
      } else {
        console.error("Invalid response when creating the inverter type.");
      }
    } catch (error) {
      console.error("Error creating invert type:", error);
    }
  };

  return (
    <Card elevation={5}>
      <CardContent>
        <Typography variant="h5">Make users admin</Typography>
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            "align-items": "center",
          }}
        >
          <Grid item xs={12} sm={8}>
            <TextField
              select
              label="Users"
              value={userRoleChange}
              onChange={handleUserRoleChangeInputChange}
              fullWidth
              margin="normal"
              displayEmpty // Add this prop to display the empty value
            >
              {users.map((u) => (
                <MenuItem value={u}>
                  {u.email} - {u.user_role}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              justifyContent: "center",
              "align-items": "center",
            }}
          >
            <Button
              variant="contained"
              color="info"
              onClick={handleUserRoleChangeSubmit}
            >
              Switch Role
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RoleManagement;
