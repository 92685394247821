import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import apiService from "../../services/apiService";

const UserStats = () => {
  const [userStats, setUserStats] = useState(null);
  const [plants, setPlants] = useState([]);
  const [inverters, setInverters] = useState([]);

  // Fetches the statistics of the user
  useEffect(() => {
    const fetchUserStats = async () => {
      try {
        const response = await apiService.getUserStats();
        setUserStats(response["stats"]);
      } catch (error) {
        console.error("Error fetching user stats:", error);
      }
    };

    fetchUserStats();
  }, []);

  // Fetches plant data
  React.useEffect(() => {
    const fetchPlants = async () => {
      try {
        const response = await apiService.getPlants();

        if (response) {
          setPlants(response);
        } else {
          console.error(
            "Invalid response format. 'plants' property not found."
          );
        }
      } catch (error) {
        console.error("Error fetching plants:", error);
      }
    };
    fetchPlants();
  }, []);

  // Fetches inverter data
  React.useEffect(() => {
    const fetchInverters = async (id) => {
      try {
        const response = await apiService.getInverters(id);

        if (response && response.hasOwnProperty("inverters")) {
          return response.inverters;
        } else {
          console.error(
            "Invalid response format. 'inverters' property not found."
          );
        }
      } catch (error) {
        console.error("Error fetching inverters:", error);
      }
    };

    const updateInverters = async () => {
      let inverterList = [];
      for (let plant of Object.values(plants.active_plants)) {
        const inverters = await fetchInverters(plant.id);
        inverterList = inverterList.concat(inverters);
      }
      setInverters(inverterList);
    };

    if (plants && plants.active_plants) {
      updateInverters();
    }
  }, [plants]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* overall stats */}
        {userStats && (
          <Card sx={{ padding: 1 }} elevation={3}>
            <CardContent>
              <Typography variant="h6">Your active PV Systems</Typography>
              <Typography>
                <strong>Active Plants: </strong>
                {userStats.plant_amount}
              </Typography>
              <Typography>
                <strong>Active Inverters: </strong>
                {userStats.inverter_amount}
              </Typography>
              <Typography>
                <strong>Active Strings: </strong>
                {userStats.string_amount}
              </Typography>
              <Typography>
                <strong>Modules: </strong>
                {userStats.module_amount}
              </Typography>
              <Typography>
                <strong>Total AC Power: </strong>
                {Math.round((userStats.total_ac_power + Number.EPSILON) * 10) /
                  10}{" "}
                kW
              </Typography>
              <Typography>
                <strong>Total DC Power: </strong>
                {Math.round((userStats.total_dc_power + Number.EPSILON) * 10) /
                  10}{" "}
                kW
              </Typography>
            </CardContent>
          </Card>
        )}
      </Grid>

      {/* Inverter lifetime */}
      <Grid item xs={12}>
        {userStats && inverters && (
          <Card sx={{ padding: 1 }} elevation={3}>
            <CardContent>
              <Typography variant="h6">
                Lifetime of your working Inverters
              </Typography>
              {inverters
                .filter((inverter) =>
                  ["operational", "repaired"].includes(inverter.status)
                )
                .map((inverter, index) => (
                  <Typography marginBottom={0.5}>
                    <strong>{inverter.name}: </strong>
                    {Math.round(
                      (inverter.lifetime / 365 + Number.EPSILON) * 10
                    ) / 10}{" "}
                    years ({inverter.lifetime} days)
                  </Typography>
                ))}
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default UserStats;
