import React, { useState, useEffect } from "react";

import { Typography, Grid } from "@mui/material";
import apiService from "../services/apiService";
import NewTypeCard from "../components/Admin/NewTypeCard";
import RoleManagement from "../components/Admin/RoleManagement";
import KaplanMeier from "../components/KaplanMeier";
import MapAdmin from "../components/Admin/MapAdmin";
import PlantControl from "../components/Admin/PlantControl";

function Admin() {
  // Current User
  const [currentUser, setCurrentUser] = useState({});
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiService.getAuth();

        if (response && response.hasOwnProperty("user")) {
          setCurrentUser(response.user);
        } else {
          console.error("Invalid response format.");
        }
      } catch (error) {
        console.error("Error fetching InverterTypes:", error);
      }
    };

    fetchUser();
  }, []);

  return currentUser.user_role === "admin" ? (
    <Grid
      container
      spacing={2}
      style={{ maxWidth: 800, margin: "auto", padding: 20 }}
    >
      <Grid item xs={12}>
        <MapAdmin height="400px" width="100%" />
      </Grid>

      <Grid item xs={12}>
        <KaplanMeier />
      </Grid>

      {/* Role management */}
      <Grid item xs={12}>
        <RoleManagement />
      </Grid>

      {/* Plant management */}
      <Grid item xs={12}>
        <PlantControl />
      </Grid>

      {/* Inverter Type Management */}
      <Grid item xs={12}>
        <NewTypeCard
          title={"Inverter Type Management"}
          fetchNewTypes={apiService.getInverterTypes}
          createNewType={apiService.createInverterType}
          specifier={"Inverter"}
        />
      </Grid>

      {/* Module Type Management */}
      <Grid item xs={12}>
        <NewTypeCard
          title={"Module Type Management"}
          fetchNewTypes={apiService.getModuleTypes}
          createNewType={apiService.createModuleType}
          specifier={"Module"}
        />
      </Grid>
    </Grid>
  ) : (
    <Typography variant="h5">No Access</Typography>
  );
}

export default Admin;
